var isParentMode = false;
if (Cookies.get('isParentMode')) {
    isParentMode = Cookies.get('isParentMode').toLowerCase() === 'true';
}

function initChangeModeToggle() {
    updateActiveMode(isParentMode);
    $('.view-mode-switcher').prop('checked', isParentMode);
    $('.view-mode-switcher').on('click', function () {
        changeMode($(this));
    });

    refreshMenu();
    updateParentModeClass(isParentMode);

    setTimeout(function () {
        $(".switcher-tooltip").attr('hidden', true)
    }, 5000);
}

function changeMode(checkbox) {
    isParentMode = checkbox.prop("checked") === 'true' || !isParentMode;
    updateParentModeClass(isParentMode);
    Cookies.set('isParentMode', isParentMode);
    refreshMenu();
    refreshPageContent();
    updateActiveMode(isParentMode);
}

function updateActiveMode(isParentMode) {
    var dataVal = isParentMode ? 1 : 0;
    $('.header-cont .togle-wraper p').removeClass('active');
    $('.header-cont .togle-wraper p[data-value="' + dataVal + '"]').addClass('active');
}

function updateParentModeClass(isParentMode) {
    if (isParentMode) {
        $('#main-div').addClass("parent");
    } else {
        $('#main-div').removeClass("parent");
    }
}

function refreshMenu() {
    if (currentController) {
        Url = "/" + currentController + "/GetMenu"
    }

    $.ajax({
        url: Url,
        success: function (data) {
            $(".main-nav").html(data);
        }
    });
}

function refreshPageContent() {
    if (currentController) {
        Url = "/" + currentController + "/RefreshMode"
    }

    $("#loader").removeAttr("hidden");
    $("#loader .loader-message").removeAttr("hidden");
    $.ajax({
        url: Url,
        data: { 'nodeAlias': alias },
        success: function (data, textStatus, jqXHR) {
            var ct = jqXHR.getResponseHeader("Content-Type");
            var mx = ct.match("text\/html");
            if (mx != null) {

                var loadMoreElem = $('#load-data', $(data));
                if (loadMoreElem && loadMoreElem.length > 0) {
                    var dataPage = loadMoreElem.attr('data-page');
                    if (!dataPage || dataPage === '') {
                        window.history.pushState("", "", "?page=" + 1);
                    }
                    else {
                        window.history.pushState("", "", "?page=" + dataPage);
                    }
                }

                $("#content-body").html(data);
            }
            else {
                location.href = data.redirectUrl;
            }

            $('.books-carousel').slick(getBookSliderSettings());
            $('.widget-carousel').slick(getWidgetSliderSettings());
            $('.trivia-carousel, .poll-carousel').slick(getWidgetCardsSliderSettings());
            initChosen();
            setAvatarsSettings();
            // Remove empty space from <i>
            $("#selecting-a-book .tick-icon i").each(function () {
                $(this).html($(this).html().replace(/&nbsp;/g, ''));
            });
            // Story Central Filters Collapse Show/Hide
            storyCentralFilterCollapseDisplay();
            attachStoryCentralEvents();
        },
        complete: function () {
            setTimeout(function () {
                $("#loader").attr("hidden", true);
                $("#loader .loader-message").attr("hidden", true);
            }, 1000);

        }
    });
}