
function initMemberProfile() {
    setSelectedAvatar();
    setAvatarsColor();

    var activeTab = window.alias === '' ? "account" : window.alias;
    var accLink = $(".member-nav-item");
    accLink.parent("." + activeTab).addClass('active');

    $(document).on('click', ".member-nav-item", function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr("href");
        var dataAlias = $this.data("name");
        
        $.ajax({
            url: url,
            success: function (data) {
                $("#content-body").html(data);
                window.history.pushState('', '', url);
                window.alias = dataAlias;
                $this.parent().siblings().removeClass("active")
                $this.parent().addClass("active");
                setSelectedAvatar();
                setAvatarsColor();
            }
        });
    });

    $("#main-div").on('change', "#avatarSet", function () {
        var $this = $(this);
        var url = $this.attr("data-avatars");
        var selectedGroup = $this.val();
        if (selectedGroup === '') return;
        $.ajax({
            url: url,
            data: { groupId: selectedGroup },
            success: function (data) {
                var selectedAvatar = $("#avatars").data("avatarselected")
                $("#avatars").html(data);
                setSelectedAvatar();
                setAvatarsColor();
            }
        });
    });

    $("#main-div").on("click", ".bg-colors-color input[type='radio']", function () {
        var selectedColor = $(this).data("color");
        changeAvatarsColor(selectedColor);
    });

    $("#main-div").on("click", ".btn-load-more-activities", function () {
        var moreLink = $(this);
        $.ajax({
            url: moreLink.attr("href"),
            data: { type: $('#activity-filter').val() },
            success: function (response) {
                var groupId, data, group;
                var loadMoreActivitiesContainer = $('#load-activities');
                for (var i = 0; i < response.result.length; i++) {
                    data = response.result[i];
                    groupId = data.group.replace(' ', '-').toLowerCase();
                    group = loadMoreActivitiesContainer.find('#' + groupId);
                    if (!group || group.length == 0) {
                        group = $('<section class="daily-activity"><div class="row"><div class="col-md-12 col-xl-10 daily-activity-item" id="' + groupId + '"><div class="daily-activity-title"><h4>' + data.group + '</h4></div></div></div></section>');
                        loadMoreActivitiesContainer.append(group);
                        group = loadMoreActivitiesContainer.find('#' + groupId);
                    }

                    group.append(data.activityHtml);
                }

                var url = response.moreUrl;
                updateLoadMoreLink(loadMoreActivitiesContainer, moreLink, url);
            }
        });

        return false;
    });

    $("#main-div").on('change', "#activity-filter", function () {
        var $this = $(this);
        var url = $this.attr("data-filter");
        var moreActivitiesLink = $('.btn-load-more-activities');
        var selectedGroup = $this.val();
        $.ajax({
            url: url,
            data: { type: selectedGroup },
            success: function (response) {
                var loadMoreActivitiesContainer = $('#load-activities');
                var data = $($.parseHTML(response));
                var url = data.attr('data-url');
                $('#load-activities').html(data.html());
                updateLoadMoreLink(loadMoreActivitiesContainer, moreActivitiesLink, url);
            }
        });
    });

    $(document).on('click', 'input.book-read', function (e) {
        var bookId = e.currentTarget.getAttribute('book-id');
        var bookAlias = e.currentTarget.getAttribute('book-alias');
        var bookUrl = e.currentTarget.getAttribute('book-url');
        var container = $('div[bookshelf-item-footer="' + bookAlias + '"]');
        $.ajax({
            url: '/Profile/UpdateBookshelf',
            type: 'Post',
            data: { bookId: bookId },
            success: function (data) {
                if (data === 'True') {
                    container.html('<div class="book-read without-review"><div class="left"><i class="icon icon-check-circle"></i> Book Read</div></div>');
                }
                else {
                    container.html(`<div class="book-read"><div class="left"><i class="icon icon-check-circle"></i> Book Read</div><div class="right"><a class="c-btn c-btn-orange small" href="${bookUrl}" leave-review-bookshelf="${bookAlias}">Review Book</a></div></div>`);
                }
            }
        });
    });

    $(document).on('change', 'select[filter-type]', function (e) {
        var selectedGenre = $('#select-genre').val();
        var selectedStatus = $('#select-status').val();
        var selectedDate = $('#select-date').val();
        
		var counterFound = 0;
        $("div.bookshelf-card").each(function () {
            if (($(this).attr('book-genre').split("|").indexOf(selectedGenre) > -1 || selectedGenre == "All") && ($(this).attr('book-status') == selectedStatus || selectedStatus == "All") && ($(this).attr('book-ordered') == selectedDate || selectedDate == "All")) {
                $(this).show();
				counterFound++;
            }
            else {
                $(this).hide();
            }
        });

		if(counterFound == 0)
			$('#bookshelf-noresults').css('display', '');
		else
			$('#bookshelf-noresults').css('display', 'none');
    });
}

function updateLoadMoreLink(loadMoreContainer, moreLink, url) {
    loadMoreContainer.attr('data-url', url);
    moreLink.attr('href', url);
    if (url === '') {
        moreLink.hide();
    }
    else {
        moreLink.show()
    }
}

function setAvatarsColor() {
    var selectedColor = $(".bg-colors-color input[type='radio']:checked").data("color");
    if (selectedColor) {
        changeAvatarsColor(selectedColor);
    }
}

function setSelectedAvatar() {
    var selectedAvatar = $("#avatars").data("avatarselected");
    var avatarFound = false;
    var avatars = $('[name="UserProfile.Avatar"]');
    if (avatars && avatars.length > 0) {
        avatars.each(function () {
            if ($(this).val() == selectedAvatar) {
                $(this).prop("checked", true);
                avatarFound = true;
            }
        });

        if (!avatarFound) {
            avatars.eq(0).prop("checked", true);
        }
    }
}

function changeAvatarsColor(color) {
    $(".icons-set-item > label > span").css("background-color", color);
}

function setAvatarsSettings(){
    setAvatarsColor();
    setSelectedAvatar();
}

function userProfileUpdated(result) {
    setAvatarsSettings();
    getActionBadge("ProfileChange");
    $('.avatar.kid-avatar').each(function(i){
        $(this).attr("style", $('.user-info-img', result).attr('style'));
        $(this).find('img').attr("src", $('.user-info-img img', result).attr('src'));
    })
}