(function ($) {
    'use strict';

    $.fn.formAutoPost = function (options) {
        // Default settings
        var settings = $.extend({
            targetContainerSelector: "#target-list",
            url: "",
            event: "click",
            beforeSend: function () { },
            success: function () { }
        }, options);

        var onSuccess = function (data) {
            if (typeof (settings.success) == "function") {
                settings.success();
            }
            $(settings.targetContainerSelector).html(data);
            attachListGridView();
        }

        var getAjaxParameters = function (htmlControl) {
            var $form = $(htmlControl).closest("form");

            return {
                url: $form.attr("action"),
                method: $form.attr("method"),
                data: $form.serialize()
            }
        }

        var ajaxCall = function () {
            if (typeof (settings.beforeSend) == "function") {
                settings.beforeSend();
            }
            var ajaxParams = getAjaxParameters(this);
            $.ajax({
                method: ajaxParams.method,
                url: ajaxParams.url,
                data: ajaxParams.data,
                success: onSuccess
            });
        }

        if (settings.event == 'change') {
            return this.change(ajaxCall);
        }
        else {
            return this.click(ajaxCall);
        }

    };

}(jQuery));