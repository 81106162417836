function addLoadMoreLinkBehaviour() {
    $(document).on("click", '.btn-load-more', function () {
        var moreLink = $(this);
        $.get($(this).attr("href"), function (response) {
            var data = $(response, "#load-data");//$($.parseHTML(response));
            var url = data.attr('data-url');
            var dataPage = data.attr('data-page');
            if (dataPage) {
                updateQueryStringParam("page", dataPage);
            }

            $('#load-data').append(data.html());
            $('#load-data').attr('data-url', url);
            moreLink.attr('href', url);
            if (url === '') {
                moreLink.hide();
            }
        });
        return false;
    });
}