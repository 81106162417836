function attachVerficationSwitcher() {
    $('#content-body').on('click', 'input.view-verification-switcher', function () {
        const input = $('.ssn-field');
        const input2 = $('#DateOfBirth');
        const offlineMsg = $('.offline-msg');
        if ($(this).prop("checked")) {
            input.attr('disabled', true);
            input2.attr('disabled', true);
            offlineMsg.removeAttr('hidden');
            $('.verification-toggle p[data-value="1"]').addClass('active');
            $('.verification-toggle p[data-value="0"]').removeClass('active');
        } else {
            offlineMsg.attr('hidden', 'true');
            input.removeAttr('disabled');
            input2.removeAttr('disabled');
            $('.verification-toggle p[data-value="0"]').addClass('active');
            $('.verification-toggle p[data-value="1"]').removeClass('active');
        }

        input.valid();
    });

}

function attachChildManagementEvents() {
    $('#content-body').on("blur", "input[name='ChildFirstName']", function (e) {
        var childName = $(this).parents(".card-body").prev(".card-header").find(".child-name");
        if (childName && childName.length > 0) {
            var enteredValue = $(this).val();
            childName.get(0).innerText = enteredValue;
        }
    });

    $('#content-body').on('blur', "input[name='ChildEmail']", function () {
        var email = $(this).val();
        if (email && email.length > 0) {
            $('#email-delivery').show();
        }
        else {
            $('#email-delivery').hide();
        }
    });
}

function attachChildRemoveEvents(){
    $('#content-body').on("click", ".btn-remove-child-dialog", function () {
        var Url = $(this).data("url");
        $('.modal-wait .btn-remove-child').data("url", Url);
        $('.modal-wait').modal("show");
    });

    $(document).on("click", ".btn-remove-child", function () {
        var button = $(this);
        var Url = button.data("url");
        $.ajax({
            url: Url,
            success: function (response) {
                var container = $('#content-body');
                container.html(response);
                $('.modal-wait').modal("hide");
            }
        });
    });
}

function hdyhAdditionalFields() {
    $("#content-body").on("change", "#howdidyouhear", function () {
        var $this = $(this);
        var url = $this.attr("dataurl");
        var selected = $this.val();
        $.ajax({
            url: url,
            data: { selected: selected },
            success: function (data) {
                $("#additional-fields").html(data);
            }
        });
    });
}

function attachStepClick() {
    $("#content-body").on("click", ".signup-steps .step-item", function (e) {
        var link = $(this);
        var url = link.data("url");
        if (url) {
            $.ajax({
                url: url,
                success: function (data) {
                    $("#content-body").html(data);
                }
            });
        }
    });
}

$(document).ajaxSuccess(function (event, xhr, settings) {
    $.validator.unobtrusive.parse('form');
    attachChildManagementEvents();
});

$(document).ready(function () {
    attachVerficationSwitcher();
    attachChildManagementEvents();
    attachChildRemoveEvents();
    hdyhAdditionalFields();
    attachStepClick();
});