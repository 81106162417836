function initRatingEvents() {
    $(document).on('mouseover', '#stars li', function () {
        var onStar = parseInt($(this).data('value'), 10);
        $(this).parent().children('li.star').each(function (e) {
            if (e < onStar) {
                $(this).addClass('hover');
                $(this).children('i.icon').addClass('icon-star');
                $(this).children('i.icon').removeClass('icon-o-star');
            }
            else {
                $(this).removeClass('hover');
                if (!$(this).hasClass('selected')) {
                    $(this).children('i.icon').removeClass('icon-star');
                    $(this).children('i.icon').addClass('icon-o-star');
                }
            }
        });

    });

    $(document).on('mouseout', '#stars li', function () {
        $(this).parent().children('li.star').each(function (e) {
            $(this).removeClass('hover');
            if (!$(this).hasClass('selected')) {
                $(this).children('i.icon').removeClass('icon-star');
                $(this).children('i.icon').addClass('icon-o-star')
            }
        });
    });

    $(document).on('click', '#stars li', function () {
        var onStar = parseInt($(this).data('value'), 10);
        var stars = $(this).parent().children('li.star');

        for (i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
            $(stars[i]).children('i.icon').removeClass('icon-star');
            $(stars[i]).children('i.icon').removeClass('icon-o-star');
        }

        for (i = 0; i < onStar; i++) {
            $(stars[i]).addClass('selected');
            $(stars[i]).children('i.icon').addClass('icon-star');
        }
        var ratingValue = parseInt($('#stars li.selected').last().data('value'), 10);
        $('#UserReviewRating').attr('value', ratingValue);

    });
}