function showVideo(img) {
    var videoUrl = img.getAttribute("data-id");
    var videoElement = document.getElementById("video");
    videoElement.setAttribute("src", videoUrl);
}

function showAuthorDetails(authorId, bookId, nodeAlias) {
    $('#authorModal').on('shown.bs.modal', function (e) {
        initAuthorModalSlider();
    });

    //$("#authorModal").modal();
    $.ajax({
        url: '/StoryCentral/' + nodeAlias + '/' + authorId + '/' + bookId,
        success: function (result) {
            $('#modal-body').html(result);
            
           
            $("#authorModal").modal("show");
        }
    });
}

function filter(cond) {
    var attr = "f-review-type";
    var divComments = document.querySelectorAll("div[" + attr + "]");
    var buttons = document.querySelectorAll("a[" + attr + "]");
    var filter = cond.getAttribute(attr);

    for (var i = 0; i < buttons.length; i++) {
        buttons[i].classList.remove("selected");
    }

    cond.classList.add("selected");

    if (filter == "all") {
        for (var i = 0; i < divComments.length; i++)
            divComments[i].style.display = 'block';
    }
    else {
        for (var i = 0; i < divComments.length; i++) {
            if (divComments[i].getAttribute(attr) != filter) {
                divComments[i].style.display = 'none';
            }
            else {
                divComments[i].style.display = 'block';
            }
        }
    }
}

function storyCentralFilterCollapseDisplay() {
    $(".story-central-books-list .collapse").each(function () {
        if ($(window).width() > 992) {
            //$(this).addClass('show')
            $(this).collapse('show')
        }/*else {
            $(this).collapse('hide')
        }*/
    });
}

function attachListGridView() {
    const modeName = "booklistmode";
    var gecookie = Cookies.get(modeName);
    if (gecookie) {
        $('.book-view').removeClass('selected');
        $('.' + gecookie).addClass('selected');
        $('.card-items').addClass(gecookie);
    }

    $("#content-body").on('click', '.book-view', function (e) {
        e.preventDefault();
        var item = $(this);
        $('.book-view').removeClass('selected');
        item.addClass('selected');

        if (item.hasClass('list')) {
            Cookies.set(modeName, 'list');
            $('.card-items').removeClass('grid');
        } else if (item.hasClass('grid')) {
            $('.card-items').addClass('grid');
            Cookies.set(modeName, 'grid');
        }
    });
}

function attachStoryCentralEvents() {
    // List Grid View On Story Central
    const $small = '(max-width: 767px)';
    //const mq = window.matchMedia($small);
    //mq.addListener(windowWidthChange);

    $(".filter-item, .sort-item").formAutoPost({ targetContainerSelector: "#product-list", event: "change", success: formUrl });
    $('button.reset-filter').formAutoPost({
        targetContainerSelector: "#product-list", event: "click", success: formUrl, beforeSend: function () {
            $('.filter-item').val("");
            $("#themes").val([]).trigger("chosen:updated");
        }
    });

    $('button.reset-sort').formAutoPost({
        targetContainerSelector: "#product-list", event: "click", success: formUrl, beforeSend: function () {
            $('.sort-item').val("");
            $("#themes").val([]).trigger("chosen:updated");
        }
    });

    $(document).on('click', '#book-preview-link', function () {
        var isMobile = window.matchMedia("only screen and (max-width: 992px)").matches;

        if (isMobile) {
            var src = $("iframe.preview-iframe").attr("src");
            window.open(src);
        }
        else {
            $('#modal-pdf').modal('show');
        }
    });
}

function formUrl() {
    $('.filter-item').each(function (i, e) {
        var value = $(e).val();
        var name = e.id;
        updateQueryStringParam(name, value);
    });

    var sort = $('#sort').val();
    updateQueryStringParam('page', 1);
    updateQueryStringParam('sort', sort);
}

function initChosen() {
	var placeholder = $('#themes').attr('placeholder');
	$('#themes').chosen({ placeholder_text_multiple: placeholder });
}

function windowWidthChange(mq) {
    const bookCards = $('.mainBookCard');
    const cardItems = $('.card-items');
    if (mq.matches && cardItems) {
        cardItems.removeClass('grid');
        bookCards.each(function (i, bookCard) {
            bookCard.classList.remove('grid')
        });
    }
}