$(function () {
    initUIFixes();
    initSmoothScroll();
    initChangeModeToggle();
    attachToggleSearch();
    addLoadMoreLinkBehaviour();
    blogCommentReply();
    attachListGridView();
    initChosen();
    attachStoryCentralEvents();
    initRatingEvents();
    initMemberProfile();
    submitTriviaAnswer();
    submitPollAnswer();
    attachWidgetEvents();
    initSearchCategoryFilter();
    initModalsOverflow();
    displayModalByUrl();
    paginationAnimate();
    // Story Central Filters Collapse Show/Hide
    storyCentralFilterCollapseDisplay();
    blogYearsLessMore()

    var query = getQueryParams(document.location.search);
    if (query.returnUrl) {
        $('#returnUrl').val(query.returnUrl);
    }
    else {
        $('#returnUrl').val(window.location.pathname);
    }
});

$(document).ajaxStart(function () {
    $("#loader").removeAttr("hidden");
});

$(document).ajaxComplete(function () {
    $("#loader").attr("hidden", true);
});

$(window).resize(function () {
    // Story Central Filters Collapse Show/Hide
    storyCentralFilterCollapseDisplay();
});

// functions to run to detect and alert user about an IE browser
function msieversion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
		return true;
    else  // If another browser, return 0
        return false;
}

if(msieversion() && !Cookies.get('showedIEWarning'))
{
    $.ajax({
        url: '/Authentication/GetInternetExplorerOutdated/',
        success: function (response) {
			alert(response);

			// write cookie so that we don't continue to alert them
			Cookies.set('showedIEWarning', true);
        }
    });
}

function initSmoothScroll() {
    $(document).on('click', '.tabs-item a[href^="#"], #leave-review-btn', function (event) {
        event.preventDefault();
        windowsize = $(window).width();
        var target = $.attr(this, 'href');
        if (!target) {
            target = $.attr(this, 'data-target')
        }

        if (target) {
            if (windowsize > 1200) {
                $('html, body').animate({
                    scrollTop: $(target).offset().top
                }, 25, "linear");
            } else {
                $('html, body').animate({
                    scrollTop: $(target).offset().top - 60
                }, 25, "linear");
            }
        }
    });
}

function initModalsOverflow() {
    $('.modal').on('show.bs.modal', function (e) {
        $("body").css("overflow", "hidden");
    })
    $('.modal').on('hide.bs.modal', function (e) {
        $("body").css("overflow", "");
    })

    $('.modal').on('hide.bs.modal', function (e) {
        $(".modal").css("overflow-y", "auto");
    })
}

function initUIFixes() {
    // Remove empty space from <i>
    $("#selecting-a-book .tick-icon i").each(function () {
        $(this).html($(this).html().replace(/&nbsp;/g, ''));
    });

    // About Page: Collapse prevent close
    $('.stepper.accordion.stepper-desktop .step-item').on('click', function (e) {
        var attr = $(this).attr('aria-expanded');
        if (attr === 'true') {
            e.stopPropagation();
        }
    })

    // Prevent Scroll On macOS,IOS
    $('.stepper.accordion').on('show.bs.collapse', function (e) {
        e.stopPropagation();
    })
}

function displayModalByUrl() {
    var type = window.location.hash.split('#');
    for (var i = 1; i < type.length; i++) {
        var element = document.getElementById("modal-" + type[i]);
        if (element != null && $("#modal-" + type[i]).hasClass('modal')) {
            $("#modal-" + type[i]).modal('show');
        }
    }
}

function paginationAnimate() {
    $(document).on('click', '.pagination li', function (e) {
        
        if ($(this).hasClass("search-pager")) {
            e.preventDefault();
            windowsize = $(window).width();
            if ($(this).hasClass("reviews-pager")) {
                if (windowsize > 1200) {
                    $('html, body').animate({
                        scrollTop: $('#reviewList').offset().top
                    }, 1);
    
                } else {
                    $('html, body').animate({
                        scrollTop: $('#reviewList').offset().top - 60
                    }, 1);
                }
            }
            if ($(this).hasClass("search-pager")) {
                window.scrollTo(0, 300);
            }
        }

        booskPagerInit($(this), windowsize);
    });
}

function booskPagerInit(element, windowsize) {
    if (element.hasClass("book-pager")) {
        var page = element.find('a').text();
        updateQueryStringParam("page", page);
        if (windowsize > 1200) {
            $('html, body').animate({
                scrollTop: $('#load-data').offset().top
            }, 1);

        } else {
            $('html, body').animate({
                scrollTop: $('#load-data').offset().top - 60
            }, 1);
        }
    }
}

function blogYearsLessMore() {
    $(document).on('click', '.blog-filter .more-items', function (e) {
        if ($(this).hasClass("collapsed")) {
            $(this).html("more <i class='icon icon-chevron-right'></i>");
        }
        else {
            $(this).html("less <i class='icon icon-chevron-right'></i>");
        }
    });
}