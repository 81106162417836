function getNewBadges() {
    var Url = "/BadgesNotification/GetNewBadges";
    $.ajax({
        url: Url,
        success: function (response) {
            appendBadgesDialog(response);
        }
    });
}

function getPageViewBadges() {
    var Url = "/BadgesNotification/GetPageViewBadges";
    $.ajax({
        url: Url,
        data: { pageUrl: window.pageUrl },
        success: function (response) {
            appendBadgesDialog(response);
        }
    });
}

function getActionBadge(actionType) {
    var Url = "/BadgesNotification/GetActionBadge";
    $.ajax({
        url: Url,
        data: { "actionType": actionType },
        success: function (response) {
            appendBadgesDialog(response);
        }
    });
}

function getBadges(groups) {
    var Url = "/BadgesNotification/GetBadges";
    $.ajax({
        url: Url,
        data: { "groups": groups },
        success: function (response) {
            appendBadgesDialog(response)
        }
    });
}

function appendBadgesDialog(response) {
    if (response) {
        var htmlData = $.parseHTML(response);
        $(htmlData).attr("id", 'modal-badge');
        $("#main-div").append(htmlData);
        attachBadgesDilogOpenEvent();
        $("#modal-badge").modal("show");
        attachBadgesDilogCloseEvent();
    }
}

function appendBadgesDialogs(response) {
    if (response && response.length > 0) {
        var htmlData;
        for (var i = 0; i < response.length; i++) {
            htmlData = $.parseHTML(response[i].badgeDialog);
            $(htmlData).attr("id", 'modal-badge-' + i);
            $("#main-div").append(htmlData);
        }

        $("#modal-badge-0").modal("show");
        attachBadgesDilogCloseEvent();
    }
}

function attachBadgesDilogCloseEvent() {
    $('.modal-badge').on('hidden.bs.modal', function (e) {
        var nextModal = $(this).next('.modal-badge');
        $(this).remove();
        if (nextModal && nextModal.length > 0) {
            nextModal.modal("show");    
        }
    });
}

function attachBadgesDilogOpenEvent() {
    $('.modal-badge').on('shown.bs.modal', function () {
        initBadgesSlider();
    });
}

$(document).ready(function () {
    var query = getQueryParams(document.location.search);
    if (query.badges && query.badges == 1) {
        getNewBadges();
    }
    else {
        getPageViewBadges();
    } 
});