function pollSubmitted() {
    getBadges("polls;holidays");
}

function blogCommentSubmitted(response) {
    getBadges("comments;holidays");
}

function reviewSubmitted(response) {
    var data = $($.parseHTML(response.responseText));
    if (data.find('.error-message-pj').length == 0) {
        getBadges("reviews;holidays");
    }
}

function triviaSubmitted() {
    getBadges("holidays");
}

function blogCommentReply() {
    $(document).on("click", ".lnk-comment-reply", function (e) {
        e.preventDefault();
        var replyLink = $(this);
        var url = replyLink.attr("data-action");
        var c_id = replyLink.attr("data-comment");
        var d_id = replyLink.attr("data-document");
        var contentHeight = replyLink.parent().parent().height();
        contentHeight += 35;
        
        $.ajax({
            url: url,
            data: { ParentCommentId: c_id, DocumentId: d_id },
            success: function (data, textStatus, jqXHR) {
                if ($("#reply-comment")) {
                    $("#reply-comment").remove();
                }

                $("#replies_" + c_id).append(data);
                $('html, body').animate({
                    scrollTop: $("#reply-comment").offset().top - contentHeight
                }, 25, 'linear');
            }
        });
    });
}

function loginSuccess(response) {
    if (response && response.Url) {
        window.location = response.Url;
    }
}

function checkCodeSuccess(response) {
    if (response && response.Url) {
        window.location = response.Url;
    }
}