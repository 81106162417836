$(document).ready(function () {
    $(document).on('click', 'button[replace]', function () {
        var replace = $(this).attr('replace');
        var bookId = $(this).attr('book-id');
        var selectedBook = $(this).attr('current-book');
        chooseBook(replace, bookId, selectedBook, false);
    });

    $(document).on('click', '#replace-book', function () {
        var bookId = $(this).attr('book-id');
        var selectedBook = $(this).attr('current-book');
        chooseBook("true", bookId, selectedBook, true);
        $('.modal-replace-book').on('hidden.bs.modal', function (e) {
            $('.modal-replace-book').remove();
        });
    });
});

function chooseBook(replace, bookId, selectedBook, confirm) {
    $.ajax({
        url: '/BookDetails/ChooseBook',
        type: 'POST',
        data: { 'bookId': bookId, 'replace': replace, 'confirmReplace': confirm },
        success: function (result) {
            if (!result) return;

            var buttonSelected = "[book-id='" + selectedBook + "'].book-selection-buttons";
            var buttonCurrent = "[book-id='" + bookId + "'].book-selection-buttons";
			var selectionClosed = result.isClosed;

            if (result.dialog) {
                $("#content-body").append(result.dialog);

				if(selectionClosed)
				{
					$('.modal-book-selected').modal('show');
					$('.modal-book-selected').on('hidden.bs.modal', function (e) {
						$('.modal-book-selected').remove();
					});
				}

            }

            if (replace === "true") {
                if (confirm) {
                    $(buttonSelected).html(result.buttonsPrevious);
                    $(buttonCurrent).html(result.buttonsCurrent);

                    $('.modal-replace-book').modal('hide');
                    $('.modal-replace-book').on('hidden.bs.modal', function (e) {
                        $('.modal-replace-book').remove();
                    });
                    $("button[replace]").each(function () {
                        $(this).attr('current-book', bookId);
                    });

                    getBadges("Genres;BookChosen");
                }
                else {
                    $('.modal-replace-book').modal('show');
                    $('.modal-replace-book').on('hidden.bs.modal', function (e) {
                        $('.modal-replace-book').remove();
                    });
                    $('#replace-book').attr('book-id', bookId);
                    $('#replace-book').attr('current-book', selectedBook);
                }
            }
            else {
                $(buttonCurrent).html(result.buttonsCurrent);
                $('.modal-book-selected').modal('show');
                $('.modal-book-selected').on('hidden.bs.modal', function (e) {
                    $('.modal-book-selected').remove();
                    getBadges("Genres;BookChosen");
                });
                $("button[replace]").each(function () {
                    $(this).attr('replace', "true");
                    $(this).attr('current-book', bookId);
                });
            }
        }
    });
}