function hideDevMenu() {
    $('.dev-test').toggleClass("hide-dev-menu");
}

function toogleSearch() {
    $(".search-toggle").toggleClass("active");
}

function attachToggleSearch() {
    $('.search-toggle-btn, .search-input-box span').on('click', function () {
        toogleSearch();
    });
}

function initSearchCategoryFilter() {
    $(".search-head .section-filter-item").on('click', function (e) {
        e.preventDefault();
        var typeSearch = $(this).val();
        var textSearch = $("#text-search").val();
        $("#type-search").val(typeSearch);


        $(".section-filter-item").removeClass('selected');
        $(this).addClass('selected');

        if (currentController) {
            Url = "/" + currentController + "/Search"
        }

        $.ajax({
            url: Url,
            data: { searchText: textSearch, type: typeSearch },
            success: function (data) {
                $("#search-result").html(data);
                //window.history.pushState("", "", "?searchText=" + textSearch + "&type=" + typeSearch);
                updateQueryStringParam("searchText", textSearch);
                updateQueryStringParam("type", typeSearch);
                updateQueryStringParam("page", 1);
            }
        });
    });

    $("#search-form").on('submit', function (e) {
        var textSearch = $("#text-search").val();
        updateQueryStringParam("searchText", textSearch);
    });
}