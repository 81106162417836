function getBookSliderSettings() {
    return {
        arrows: true,
        dots: true,
        rows: 0,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><i class="icon icon-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-chevron-left"></i></button>',
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
}

function getWidgetSliderSettings() {
    return {
        arrows: true,
        dots: true,
        rows: 0,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><i class="icon icon-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-chevron-left"></i></button>',

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
}

function getWidgetCardsSliderSettings() {
    return {
        arrows: false,
        dots: true,
        rows: 0,
        infinite: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
}

$('.trivia-carousel').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    $('.trivia-card-count').text(i + '/' + slick.slideCount);
});

$('.books-carousel, .related-books-slider').slick(getBookSliderSettings());
$('.widget-carousel').slick(getWidgetSliderSettings());
$('.trivia-carousel, .poll-carousel').slick(getWidgetCardsSliderSettings());

function initBadgesSlider() {
    $('.badges-slider').slick({
        arrows: true,
        dots: true,
        rows: 0,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><i class="icon icon-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-chevron-left"></i></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
    });
}

function initAuthorModalSlider() {
    $('#carouselAutorInfo').slick({
        arrows: true,
        dots: true,
        rows: 0,
        infinite: false,
        nextArrow: '<button type="button" class="slick-next"><i class="icon icon-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon icon-chevron-left"></i></button>',
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}