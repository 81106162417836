function submitTriviaAnswer() {
    $('#main-div').on('click', '.btn-answer-trivia', function () {
        var $this = $(this);
        var url = $this.attr("data-url");
        var trivia = $this.attr("data-trivia");
        $.ajax({
            url: url,
            method: "POST",
            data: { triviaId: trivia },
            success: function (data) {
                if (data) {
                    $("[data-trivia-container='" + trivia + "']").html(data);
                    $("[data-trivia-container='" + trivia + "']").addClass("answered");
                    triviaSubmitted();
                }

            }
        });
    });
}

function submitPollAnswer() {
    $('#main-div').on('click', '.btn-poll-submit', function () {
        var $this = $(this);
        var url = $this.attr("data-url");
        var poll = $this.attr("data-poll");
        var answer;
        var answerElem = $("[data-poll-container='" + poll + "']").find('[name="answerId"]:checked').get(0)
        if (answerElem) {
            answer = $(answerElem).val();
        }
        if (answer) {
            $.ajax({
                url: url,
                method: "POST",
                data: { pollId: poll, answerId: answer },
                success: function (data) {
                    if (data) {
                        $("[data-poll-container='" + poll + "']").html(data);
                        pollSubmitted();
                    }

                }
            });
        }
    });

    $('#main-div').on('click', '.btn-poll-undo', function () {
        var $this = $(this);
        var url = $this.attr("data-url");
        var poll = $this.attr("data-poll");
        var answer = $this.attr("data-answer");;
        if (answer) {
            $.ajax({
                url: url,
                method: "POST",
                data: { pollId: poll, answerId: answer },
                success: function (data) {
                    if (data) {
                        $("[data-poll-container='" + poll + "']").html(data);
                    }
                }
            });
        }
    });
}

function attachWidgetEvents() {
    $('#main-div').on('mouseover', "#undo-section", function () {
        $('.custom-tooltip').removeAttr('hidden');
    }).on('mouseleave', "#undo-section", function () {
        $('.custom-tooltip').attr('hidden', true);
    });
    
    $('#main-div').on('click', ".poll-radio-select", function () {
        $('.btn-poll-submit').removeAttr('disabled');
    });
}